<template>
  <section class="">
    <div class="columns is-centered">
      <div class="column is-half has-text-centered">
        <b-button rounded outlined type="is-primary" icon-left="download" @click="exportToCSV()">
          Export to CSV
        </b-button>
      </div>
    </div>
    <div class="box">
      <div class="columns is-centered">
        <div class="column is-half">
          <b-field label="Filter by tags">
            <b-taginput
              v-model="tags"
              :data="filteredTags.length === 0 ? allTags : filteredTags"
              autocomplete
              :allow-new="false"
              icon="label"
              placeholder="Search a tag"
              @typing="getFilteredTags"
            />
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-table
            :data="orderedSong"
            :striped="true"
            :bordered="true"
            :mobile-cards="true"
            :paginated="true"
            per-page="40"
            :hoverable="true"
            pagination-position="bottom"
          >
            <template slot-scope="props">
              <b-table-column field="cover" class="custom" width="70">
                <figure class="image is-64x64">
                  <img class="is-rounded inset" :src="props.row.cover" />
                </figure>
              </b-table-column>
              <b-table-column field="name" label="Song name" sortable>
                <router-link :to="getRouterLink(props.row.id)">
                  {{ getLabel(props.row) }}
                </router-link>
              </b-table-column>
              <b-table-column field="author" label="Author" sortable>
                {{ props.row.author }}
              </b-table-column>
              <b-table-column field="tags" label="Tags">
                <b-taglist v-if="props.row.tags">
                  <b-tag
                    v-for="t in props.row.tags"
                    :key="t"
                    rounded
                    closable
                    type="is-info"
                    @close="deleteTag(props.row.id, t)"
                  >
                    {{ t }}
                  </b-tag>
                </b-taglist>
              </b-table-column>
              <b-table-column label="">
                <b-button rounded size="is-small" @click="addTag(props.row.id)">
                  Add tag
                </b-button>
                <b-modal :active.sync="isTagModalActive" :width="320">
                  <div class="card">
                    <div class="card-content">
                      <div class="content">
                        <div class="columns is-mobile">
                          <div class="column">
                            <b-field label="Add a tag to your song">
                              <b-input v-model="tag" />
                            </b-field>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column has-text-right">
                            <b-button
                              class="has-margin-right-5"
                              @click="
                                isTagModalActive = false;
                                tag = '';
                                currentTag = '';
                              "
                            >
                              Cancel
                            </b-button>
                            <b-button type="is-primary" @click="saveTag()">
                              Save
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </b-table-column>
              <b-table-column field="posts" label="Total posts" sortable numeric>
                {{ props.row.posts | formatNumber }}
              </b-table-column>
              <b-table-column field="new_posts" label="Last 24h" sortable numeric>
                <span
                  :class="[
                    { 'has-text-danger': props.row.new_posts <= 0 },
                    { 'has-text-success': props.row.new_posts > 0 },
                  ]"
                  >{{ props.row.new_posts | formatNumber }}</span
                >
              </b-table-column>

              <b-table-column label="% variation" numeric>
                {{
                  ((1 - (props.row.posts - props.row.new_posts) / props.row.posts) * 100).toFixed(2)
                }}
              </b-table-column>

              <b-table-column width="40">
                <b-button
                  type="is-primary"
                  tag="router-link"
                  :to="getRouterLink(props.row.id)"
                  icon-right="finance"
                />
              </b-table-column>
              <b-table-column width="40">
                <a :href="getLink(props.row.url)" target="_blank">
                  <b-button type="is-link" outlined icon-right="link" />
                </a>
              </b-table-column>
              <b-table-column width="40">
                <b-button
                  type="is-danger"
                  outlined
                  icon-right="delete"
                  @click="toDelete(props.row.id)"
                />
                <b-modal :active.sync="isCardModalActive" :width="320">
                  <div class="card">
                    <div class="card-content">
                      <div class="content">
                        <div class="columns is-mobile">
                          <div class="column">
                            Are you sure ?
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column has-text-right">
                            <b-button class="has-margin-right-5" @click="isCardModalActive = false">
                              Cancel
                            </b-button>
                            <b-button type="is-danger" @click="deleteSearch()">
                              Delete
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isCardModalActive: false,
      isTagModalActive: false,
      current: "",
      currentTag: "",
      tag: "",
      tags: [],
      filteredTags: [],
    };
  },
  computed: {
    orderedSong() {
      const arr = this.tiktok.slice();
      const filtered = arr.filter((value, index, arr) => value.type === "song");
      filtered.sort((a, b) => a.name.localeCompare(b.name));
      if (this.tags.length !== 0) {
        const r = [];
        this.tags.forEach(t => {
          filtered.forEach(element => {
            if (element.tags !== undefined) {
              element.tags.forEach(t => {
                if (this.tags.indexOf(t) !== -1) {
                  if (r.indexOf(element) === -1) {
                    r.push(element);
                  }
                }
              });
            }
          });
        });
        return r;
      }
      return filtered;
    },
    allTags() {
      const tags = [];
      const arr = this.tiktok.slice();
      const filtered = arr.filter((value, index, arr) => value.type === "song");
      filtered.forEach(element => {
        if (element.tags !== undefined) {
          element.tags.forEach(t => {
            if (tags.indexOf(t) === -1) {
              tags.push(t);
            }
          });
        }
      });
      return tags;
    },
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktok"]),
  },
  methods: {
    toDelete(t) {
      this.isCardModalActive = true;
      this.current = t;
    },
    getRouterLink(id) {
      return `/analytics/song/${id}`;
    },
    getLink(id) {
      return `https://www.tiktok.com/music/${id}`;
    },
    deleteSearch() {
      this.$store.dispatch("tiktok/deleteSearch", { id: this.current });
      this.isCardModalActive = false;
      this.current = "";
    },
    getLabel(data) {
      if (data.label !== undefined) {
        return data.label;
      }
      return data.name;
    },
    addTag(id) {
      this.isTagModalActive = true;
      this.currentTag = id;
    },
    saveTag() {
      this.$store.dispatch("tiktok/saveMusicTag", {
        id: this.currentTag,
        tag: this.tag,
      });
      this.isTagModalActive = false;
      this.currentTag = "";
      this.$store.dispatch("tiktok/getTiktok");
    },
    deleteTag(id, tag) {
      this.$store.dispatch("tiktok/deleteMusicTag", { id: id, tag: tag });
      this.$store.dispatch("tiktok/getTiktok");
    },
    getFilteredTags(text) {
      this.filteredTags = this.allTags.filter(option => {
        return option.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    exportToCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      const row = "name,author,tags,total posts,new posts,% variations,url";
      csvContent += row + "\r\n";
      this.orderedSong.forEach(function(song) {
        const name = song.label === undefined ? song.name : song.label;
        if (song.new_posts === undefined) {
          song.new_posts = 0;
        }
        const tags = song.tags !== undefined ? song.tags.join(";") : "";
        const variation = ((1 - (song.posts - song.new_posts) / song.posts) * 100).toFixed(2);
        csvContent +=
          name +
          "," +
          song.author +
          "," +
          tags +
          "," +
          song.posts +
          "," +
          song.new_posts +
          "," +
          variation +
          "," +
          "https://www.tiktok.com/music/" +
          song.url +
          "\r\n";
      });
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },
};
</script>

<style>
table.table td .image {
  height: 64px;
  width: 64px;
}
.inset {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5);
}
</style>
